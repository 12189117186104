import { Trans } from 'react-i18next';
import ErrorPage from './_error';

const HomePage = (): JSX.Element => (
    <ErrorPage
        statusCode={404}
        data={{}}
        errMessage={<Trans i18nKey="home.views.error" />}
    />
);

export default HomePage;
